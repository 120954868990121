import React, { forwardRef, useCallback, useEffect, useState } from "react";
import cm from "../../../utils/cm";
import Constrained from "../Constrained/Constrained";
import Header from "../Header/Header";
import * as classes from "./SkewPage.module.scss";

type Props = {
  children: React.ReactNode;
};

export default forwardRef(function SkewPage(
  { children }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [scrolled, setScrolled] = useState(false);
  const getScrolled = useCallback(() => {
    return window.scrollY > 10;
  }, []);
  useEffect(() => {
    const handler = () => {
      setScrolled(getScrolled());
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <>
      <div className={cm(classes.skew, scrolled && classes.scrolled)} ref={ref}>
        <div className={classes.header}>
          <Constrained>
            <Header />
          </Constrained>
        </div>
        <div className={cm(classes.layer, classes.layer1)}>
          <div className={cm(classes.layer, classes.layer2)}>
            <div className={cm(classes.layer, classes.layer3)}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
});
