import React from "react";
import Page from "../../components/layout/Page/Page";
import * as classes from "./Test.module.scss";
import SkewPage from "../../components/layout/SkewPage/SkewPage";
import H2 from "../../components/typography/H2/H2";
type Props = {};

export default function Test(props: Props) {
  return (
    <>
      <Page title="test">
        <SkewPage>sup</SkewPage>
        <H2>Hello</H2>
        <H2>Hello2</H2>
      </Page>
    </>
  );
}
